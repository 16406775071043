import React from "react"

/*Material-ui */
import { Grid, Typography, Button } from "@material-ui/core"
import { makeStyles, withStyles } from "@material-ui/core/styles"

/*Local files */
import ImagenFaces from "../images/210.png"

const useStyles = makeStyles(theme => ({
  ContenedorMain: {
    background: "linear-gradient(180deg, #FFFFFF 0%, #DEE7FF 100%)",
    width: "100%",
    height: "938px",
    "@media (max-width: 720px)": {
      height: "auto",
      overflowX: "hidden",
    },
  },
  titulo: {
    color: "#385099",
    fontSize: "42px",
    font: "800 34px/42px Montserrat",
    "@media (max-width: 720px)": {
      fontSize: "35px",
      textAlign: "center",
      width: "80%",
    },
  },
  textoparrafo: {
    textAlign: "center",
    marginTop: "3rem",
    fontSize: "20px",
    width: "54%",
    color: "#191919",
    font: "200 17px/30px Montserrat",
    "@media (max-width: 720px)": {
      fontSize: "18px",
      width: "90%",
    },
  },
  containerButton: {
    marginTop: "2rem",
  },
  containerImg: {
    marginTop: "0rem",
  },
  Semi: {
    font: "800 17px/30px Montserrat",
    textTransform: "uppercase",
  },
  image: {
    "@media (max-width: 720px)": {
      width: "100%",
    },
  },
}))

const StyledButton = withStyles({
  root: {
    background:
      "transparent linear-gradient(90deg, #4267CB 0%, #16B1CB 100%) 0% 0% no-repeat padding-box",
    borderRadius: "27px",
    border: 0,
    color: "white",
    height: 49,
    width: "275px",
    padding: "0 30px",
    boxShadow: "0px 3px 6px #0000006A",
    "@media (max-width: 720px)": {
      height: 60,
      width: "260px",
      borderRadius: "50px",
      font: "800 18px Montserrat",
    },
  },
  label: {
    textTransform: "none",
    fontFamily: "Helvetica",
    font: "800 17px/20px Montserrat",
    textDecoration: "none",
  },
})(Button)

const SectionTwo = () => {
  const classes = useStyles()
  return (
    <div>
      <Grid className={classes.ContenedorMain} id="QueEsLaNorma">
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          xs={12}
        >
          <Grid
            item
            xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Typography className={classes.titulo}>
              ¿Qué es la norma 035 STPS-2018?
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <p className={classes.textoparrafo}>
              <strong>
                Es una norma emitida para determinar las condiciones mínimas
                necesarias en materia de seguridad, salud y medio ambiente de
                trabajo, a efecto de{" "}
              </strong>
              <strong className={classes.Semi}>
                PREVENIR ACCIDENTES Y ENFERMEDADES LABORALES.
              </strong>
              <br /> <br />
              <strong>
                Tiene como objetivo establecer los elementos para identificar,
                analizar y prevenir los factores de riesgo psicosocial, así como
                para promover un entorno organizacional favorable en los centros
                de trabajo.
              </strong>
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.containerButton}
          >
            <a
              href="https://www.dof.gob.mx/nota_detalle.php?codigo=5541828&fecha=23/10/2018"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <StyledButton id="INTERESADO 2">
                Consultar sitio oficial
              </StyledButton>
            </a>
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.containerImg}
          >
            <img src={ImagenFaces} alt="Faces" className={classes.image} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default SectionTwo
