import React from "react"
import Helmet from "react-helmet"
import SecitonOne from "../components/sectionOne"
import SectionTwo from "../components/sectionTwo"
import SectionTree from "../components/sectionTree"
import Sectionfour from "../components/sectionfour"
// import Chat from "../components/chat/chat"
// import Hidden from "@material-ui/core/Hidden"

const IndexPage = () => (
  <>
    <Helmet>
      <meta http-equiv="cache-control" content="no-cache" />
      <meta http-equiv="expires" content="0" />
      <meta http-equiv="pragma" content="no-cache" />
    </Helmet>
    <SecitonOne />
    <SectionTwo />
    <SectionTree />
    <Sectionfour />
  </>
)

export default IndexPage
