import React from "react"

/*Material-ui */
import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"

/*Local files */
import ImageRun from "../images/meta_runner.svg"

const useStyles = makeStyles(theme => ({
  ContenedorMain: {
    backgroundColor: "#385099",
    width: "100%",
    height: "840px",
    "@media (max-width: 720px)": {
      height: "auto",
    },
  },
  TitleWhite: {
    color: "white",
    marginTop: "9rem",
    fontSize: "55px",
    font: "700 45px/55px Montserrat",
    "@media (max-width: 720px)": {
      width: "90%",
      fontSize: "30px",
      textAlign: "center",
    },
  },
  Parrafo: {
    color: "white",
    textAlign: "center",
    fontSize: "25px",
    font: "500 17px/30px Montserrat",
    width: "75%",
    marginBottom: "2rem",
    "@media (max-width: 720px)": {
      width: "80%",
      textAlign: "center",
    },
  },
  TituloCard: {
    color: "#313131",
    textAlign: "left",
    font: "800 40px/42px Montserrat",
    "@media (max-width: 720px)": {
      font: "800 20px/20px Montserrat",
      textAlign: "center",
    },
  },
  ParrafoCard: {
    font: "500 20px/30px Montserrat",
    "@media (max-width: 720px)": {
      font: "500 17px/25px Montserrat",
    },
  },
  containerCards: {
    "@media (max-width: 720px)": {
      height: "auto",
      justifyContent: "space-between",
    },
  },
  cardContainer: {
    borderBottom: "4px solid #FFB91C",
    width: "317px",
    height: "197px",
    "@media (max-width: 720px)": {
      width: "48%",
      marginBottom: "30px ",
      zIndex: "3",
    },
  },
  ContenedorImage: {
    marginTop: "2rem",
    "@media (max-width: 720px)": {
      marginTop: "-100px",
      marginBottom: "50px",
    },
  },
}))

const SectionTree = () => {
  const classes = useStyles()
  return (
    <Grid className={classes.ContenedorMain}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        xs={12}
      >
        <Grid
          item
          xs={12}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Typography className={classes.TitleWhite}>
            ¡Llega a la meta en 3 pasos!
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <p className={classes.Parrafo}>
            Nuestra plataforma cubre completamente las necesidades para cumplir
            con la norma, en pasos muy sencillos y con una inrterfaz fácil y
            completa.
          </p>
        </Grid>
        <Grid
          item
          xs={11}
          md={12}
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
          className={classes.containerCards}
        >
          <Card className={classes.cardContainer}>
            <CardContent>
              <Typography className={classes.TituloCard}>
                1. REGISTRA
              </Typography>
              <p className={classes.ParrafoCard}>
                Has un <strong>preregistro</strong> de todos tus empleados.
              </p>
            </CardContent>
          </Card>
          <Card className={classes.cardContainer}>
            <CardContent>
              <Typography className={classes.TituloCard}>2. APLICA</Typography>
              <p className={classes.ParrafoCard}>
                <strong>Aplica </strong> los cuestionarios correspondientes.
              </p>
            </CardContent>
          </Card>
          <Card className={classes.cardContainer}>
            <CardContent>
              <Typography className={classes.TituloCard}>3. RECABA</Typography>
              <p className={classes.ParrafoCard}>
                Se generan expedientes y <strong>evidencias </strong> para
                cumplir con la norma.
              </p>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={10}
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          className={classes.ContenedorImage}
        >
          <img src={ImageRun} alt="Run" />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SectionTree
